<template>
  <div id="forgot-password-page">
    <div class="forgot-password-content">
      <div class="content-container">
        <b-row class="forgot-password-form" no-gutters>
          <b-col
            :class="{'hide' : breakpoint !== 'xl'}"
            cols="12"
            :lg="breakpoint !== 'xl' ? 12 : 6"
          ></b-col>
          <b-col
            class="my-auto"
            cols="12"
            :lg="breakpoint !== 'xl' ? 12 : 6">
            <div class="form-container">
              <h4 class="text-center mb-3">Forgot Password</h4>
              <b-form @submit.prevent="sendResetPassEmail">
                <b-input-group prepend="Email">
                  <b-form-input
                    type="text"
                    aria-describedby="Email Input"
                    autocomplete="off"
                    placeholder="Email"
                    v-model.trim="$v.form.email.$model"
                    :state="!$v.form.email.$error"
                    @input="$v.form.email.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.email.required">
                      Email is required.
                    </span>
                    <span v-if="!$v.form.email.email && $v.form.email.required">
                      Please provide a valid email.
                    </span>
                    <span v-if="!$v.form.email.registeredEmail &&
                      $v.form.email.email && $v.form.email.required">
                      Email is unregistered.
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>

                <div class="text-center">
                  <b-button
                    class="btn-h3-orange"
                    type="submit"
                    :disabled="$v.form.$invalid">
                    Submit
                  </b-button>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <AppFooter class="footer" />
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapGetters } from 'vuex';
  import { required, email } from 'vuelidate/lib/validators';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    head : {
      title : {
        inner      : 'Forgot Password',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        loading : false,
        form    : {
          email : null,
        },
        isEmailRegistered : true,
      }
    },
    computed : {
      ...mapGetters({
        authStatus : 'auth/authStatus',
      }),
    },
    components : {
      'AppFooter' : () => import('@/components/layout/AppFooter'),
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    watch : {
      'form.email'(val) {
        this.isEmailRegistered = true;
        if (val)
          this.verifyEmail();
      },
    },
    methods : {

      /**
       * Send Reset Password Email
       */
      sendResetPassEmail() {
        this.loading = true;
        this.$http.post('auth/resetPasswordEmail', {
          email : this.form.email,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Reset Password Email sent!',
          });
          setTimeout(() => { this.$router.push('/login'); }, 800);
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Check if Email is registered
       */
      verifyEmail : _.debounce(function () {
        // verify if user email exists
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then((response) => {
          this.isEmailRegistered = response.data;
        }).catch((err) => {
          this.isEmailRegistered = err.data;
        });
      }, 500),
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'forgot-password',
        'platform' : 'website',
      });
    },
    validations : {
      form : {
        email : {
          required,
          email,
          registeredEmail() {
            return this.isEmailRegistered;
          },
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #forgot-password-page {
    background-image: $login-bg;
    background-attachment: fixed;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-container {
    padding: 2rem;
    background-color: #342aff;
    border: 10px solid $login-orange;
    border-radius: 1rem;
    box-shadow: 5px 5px 30px rgba(0,0,0, 0.8);
  }

  .form-control {
    height: auto;
  }

  .input-group-text {
    width: 10em;
    background-color: $login-orange !important;
    color: #fff;
    font-family: "WickedMouse";
    font-size: 1em;
    -webkit-text-stroke: 1px $login-purple;
  }

  .invalid-feedback {
    margin-left: 14.5em;
    color: #fff !important;
  }

  a {
    text-decoration: none !important;
  }

  .hide {
    display: none;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .forgot-password-content {
      min-height: calc(100vh - 3.5em);
      .forgot-password-form {
        height: 94vh;
      }
    }
    .input-group-text {
      display: none;
    }
    .invalid-feedback {
      margin-left: 0;
    }
  }

  /* iPhone 6/7/8 Plus */
  @media only screen
    and (device-width : 414px)
    and (device-height : 736px)
    and (-webkit-device-pixel-ratio : 3) {
    .forgot-password-content {
      min-height: calc(100vh - 2.5em);
      .forgot-password-form {
        height: calc(100vh - 9.4em);
      }
    }
  }

  /* iPhone X and Xs Max */
  @media only screen
    and (min-device-width: 375px)
    and (min-device-height: 812px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    .forgot-password-content {
      min-height: calc(100vh - 2.5em);
      .forgot-password-form {
        height: calc(100vh - 9.3em);
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .forgot-password-content {
      .forgot-password-form {
        height: calc(100vh - 3.3em);
      }
    }
    .invalid-feedback {
      margin-left: 10rem;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .forgot-password-content {
      .forgot-password-form {
        height: calc(100vh - 4.9em);
      }
    }
    .form-container {
      margin-bottom: 0;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .forgot-password-content {
      .forgot-password-form {
        height: calc(100vh - 5em);
      }
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .forgot-password-content {
      background-image: $pj-bg;
      background-attachment: fixed;
      background-position: left bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @media only screen and (min-width: $special) {
    .forgot-password-content {
      .forgot-password-form {
        padding-top: 3rem;
        height: calc(100vh - 4.2em);
      }
    }
  }
  
  @media only screen and (min-width: $xl2-min) {
    .forgot-password-content {
      min-height: calc(100vh - 3.8em);
      .forgot-password-form {
        height: calc(100vh - 10em);
      }
    }
    .invalid-feedback {
      margin-left: 14rem;
    }
  }
  
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .forgot-password-content {
      .forgot-password-form {
        height: calc(100vh - 4.6em);
      }
    }
  }
</style>